let initState = {
    is_logged: false,
    data: null
}

const app = (state = initState, action) => {
    switch(action.type){
        case 'LOGIN' :
                   
            return {
                is_logged: true,
                data: action.payload
            };

        case 'LOGOUT' :
            return {
                is_logged: false,
                data: null
            }
        case 'SET_MEMBER_DATA' :
            return {
                ...state,
                data: {
                    ...state,
                    ...action.payload
                }
            }            
        default:
            return state;
    }
}
export default app