import clientReducer from './clientReducer';
import testReducer from './testimonialReducer';
import blogReducer from './blogReducer';
import teamReducer from './teamReducer';
import slideReducer from './slidReducer';
import projectReducer from './projectReducer';
import productReducer from './productReducer';
import cartReducer from './cartReducer';
import eventReducer from './eventReducer';
import app from './app';
import member from './member'
import config from './config'
import temp_user from './temp-user'

import {combineReducers} from 'redux';

const popup = (state = false, action) => {
    switch(action.type){
        case 'SET_POPUP' :
            return action.payload
        default:
        return state;
    }
}

const rootReducer = combineReducers({    
    client: clientReducer,
    testimonial: testReducer,
    blog: blogReducer,
    team: teamReducer,
    slider: slideReducer,
    project: projectReducer,
    product: productReducer,
    cart: cartReducer,
    event: eventReducer,
    app,
    member,
    config,
    temp_user,
    popup
})

export default rootReducer;