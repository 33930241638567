const config = (state = null, action) => {
    switch(action.type){
        case 'SET_CONFIG_DATA' :
            return {
                ...action.payload
            };
        default:
        return state;
    }
}
export default config