var item = localStorage.getItem('cart');
            
const initState = JSON.parse(item);

const cartReducer = (state = initState, action) => {
    switch(action.type){
        case 'CREATE_CART' :
                   
            return {
                ...state,
                successMessage : true
            };

        case 'CREATE_CART_ERROR' :
        return {
            ...state,
            successMessage: false
        }
        default:
        return state;
    }
}
export default cartReducer