import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom';
// import App from './components/App';
import {Provider} from 'react-redux';
import store from './Store'
import Spinner from './components/page/spinner'

const LazyApp = lazy(() => import("./components/App"))

ReactDOM.render(
    <Provider store={store}>
        <Suspense fallback={<Spinner />}>
            <LazyApp />
        </Suspense>
    </Provider>,
    document.getElementById('root')
)

