let initState = {
    is_trial_active: true,
    interests: [],
    levels: [],
    professions: []
}

const app = (state = initState, action) => {
    switch(action.type){
        case 'SET_TRIAL_ACTIVE' :
                   
            return {
                is_trial_active: true
            };

        case 'SET_TRIAL_PASSIVE' :
            return {
                is_trial_active: false
            }

        case 'SET_APP_DATA' :
            return {
                ...state,
                ...action.payload
            }            

        default:
        return state;
    }
}
export default app