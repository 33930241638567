import {createStore, applyMiddleware, compose} from 'redux';
import thunk from 'redux-thunk';

import app from './Reducers/rootReducer'

const store = createStore(
    app,
    compose( 
            applyMiddleware(thunk)                  
        )
    ) 

export default store;